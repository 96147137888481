import type { DashboardTool } from '@magicschool/business-logic/tools';
import type { Category, ReturnTypeGetUserRecommendations } from '@magicschool/supabase/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';

export type ToolsStore = ToolsData & ToolsActions;
export type ToolsSortType = 'popular' | 'newest' | 'az' | 'za';

export type ToolsData = {
  selectedCategory: string;
  searchTerm: string;
  headerHeight: number;
  tools: DashboardTool[];
  recommendedTools: ReturnTypeGetUserRecommendations;
  categories: Category[];
  showStudent: boolean;
  searchResults: string[];
  sortType: ToolsSortType;
};

const defaultState: ToolsData = {
  selectedCategory: '',
  searchTerm: '',
  headerHeight: 0,
  tools: [],
  recommendedTools: [],
  categories: [],
  showStudent: false,
  searchResults: [],
  sortType: 'popular' as const,
};

export type ToolsActions = {
  setField: SetField<ToolsStore>;
};

export const createToolsPageStoreSlice = createStoreSlice('ToolsStoreData', structuredClone(defaultState), ({ setField }) => ({
  setField,
}));
