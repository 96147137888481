export type Json = string | number | boolean | null | { [key: string]: Json | undefined } | Json[];

export type Database = {
  public: {
    Tables: {
      assistant_thread_exemplars: {
        Row: {
          assistant_thread_links_id: string | null;
          created_at: string;
          id: number;
          title: string | null;
          tool_id: string | null;
          tool_uuid: string;
        };
        Insert: {
          assistant_thread_links_id?: string | null;
          created_at?: string;
          id?: number;
          title?: string | null;
          tool_id?: string | null;
          tool_uuid: string;
        };
        Update: {
          assistant_thread_links_id?: string | null;
          created_at?: string;
          id?: number;
          title?: string | null;
          tool_id?: string | null;
          tool_uuid?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_thread_exemplar_assistant_thread_links_id_fkey';
            columns: ['assistant_thread_links_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_thread_links';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_assistant_thread_exemplars_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      assistant_thread_links: {
        Row: {
          access_type: Database['public']['Enums']['assistant_thread_link_access_type'];
          assistant_thread_id: number;
          created_at: string | null;
          id: string;
          org_id: string | null;
          user_id: string;
          visits: number;
        };
        Insert: {
          access_type?: Database['public']['Enums']['assistant_thread_link_access_type'];
          assistant_thread_id: number;
          created_at?: string | null;
          id?: string;
          org_id?: string | null;
          user_id: string;
          visits?: number;
        };
        Update: {
          access_type?: Database['public']['Enums']['assistant_thread_link_access_type'];
          assistant_thread_id?: number;
          created_at?: string | null;
          id?: string;
          org_id?: string | null;
          user_id?: string;
          visits?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_thread_links_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: true;
            referencedRelation: 'assistant_threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_thread_links_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: true;
            referencedRelation: 'most_recent_room_student_messages';
            referencedColumns: ['assistant_thread_id'];
          },
          {
            foreignKeyName: 'assistant_thread_links_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'assistant_thread_links_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_thread_links_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      assistant_thread_message_edits: {
        Row: {
          assistant_thread_message_id: number;
          content: Json;
          created_at: string;
          id: string;
        };
        Insert: {
          assistant_thread_message_id: number;
          content: Json;
          created_at?: string;
          id?: string;
        };
        Update: {
          assistant_thread_message_id?: number;
          content?: Json;
          created_at?: string;
          id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_thread_message_edits_assistant_thread_message_id_fkey';
            columns: ['assistant_thread_message_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_thread_messages';
            referencedColumns: ['id'];
          },
        ];
      };
      assistant_thread_messages: {
        Row: {
          assistant_thread_id: number;
          content: Json;
          created_at: string | null;
          id: number;
          role: string;
          status: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Insert: {
          assistant_thread_id: number;
          content: Json;
          created_at?: string | null;
          id?: number;
          role: string;
          status?: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Update: {
          assistant_thread_id?: number;
          content?: Json;
          created_at?: string | null;
          id?: number;
          role?: string;
          status?: Database['public']['Enums']['row_status'];
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_thread_messages_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_thread_messages_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'most_recent_room_student_messages';
            referencedColumns: ['assistant_thread_id'];
          },
          {
            foreignKeyName: 'assistant_thread_messages_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      assistant_thread_resources: {
        Row: {
          assistant_thread_id: number;
          created_at: string | null;
          resource_id: number;
          user_id: string;
        };
        Insert: {
          assistant_thread_id: number;
          created_at?: string | null;
          resource_id?: number;
          user_id: string;
        };
        Update: {
          assistant_thread_id?: number;
          created_at?: string | null;
          resource_id?: number;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_thread_resources_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_thread_resources_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'most_recent_room_student_messages';
            referencedColumns: ['assistant_thread_id'];
          },
          {
            foreignKeyName: 'assistant_thread_resources_resource_id_fkey';
            columns: ['resource_id'];
            isOneToOne: false;
            referencedRelation: 'resources';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_thread_resources_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      assistant_threads: {
        Row: {
          assistant_data: Json | null;
          created_at: string | null;
          deleted: boolean;
          id: number;
          saved: boolean;
          status: Database['public']['Enums']['row_status'];
          title: string | null;
          tool_customization_id: string | null;
          tool_uuid: string;
          user_id: string;
        };
        Insert: {
          assistant_data?: Json | null;
          created_at?: string | null;
          deleted?: boolean;
          id?: number;
          saved?: boolean;
          status?: Database['public']['Enums']['row_status'];
          title?: string | null;
          tool_customization_id?: string | null;
          tool_uuid: string;
          user_id: string;
        };
        Update: {
          assistant_data?: Json | null;
          created_at?: string | null;
          deleted?: boolean;
          id?: number;
          saved?: boolean;
          status?: Database['public']['Enums']['row_status'];
          title?: string | null;
          tool_customization_id?: string | null;
          tool_uuid?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_threads_tool_customization_id_fkey';
            columns: ['tool_customization_id'];
            isOneToOne: false;
            referencedRelation: 'tool_customizations';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assistant_threads_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_assistant_threads_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      categories: {
        Row: {
          created_at: string | null;
          id: string;
          priority: number;
          role: Database['public']['Enums']['user_role'];
          slug: string;
          title: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          priority?: number;
          role?: Database['public']['Enums']['user_role'];
          slug: string;
          title: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          priority?: number;
          role?: Database['public']['Enums']['user_role'];
          slug?: string;
          title?: string;
        };
        Relationships: [];
      };
      category_translations: {
        Row: {
          category_id: string;
          created_at: string | null;
          id: string;
          language: string;
          title: string;
          updated_at: string | null;
        };
        Insert: {
          category_id: string;
          created_at?: string | null;
          id?: string;
          language: string;
          title: string;
          updated_at?: string | null;
        };
        Update: {
          category_id?: string;
          created_at?: string | null;
          id?: string;
          language?: string;
          title?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'category_translations_category_id_fkey';
            columns: ['category_id'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id'];
          },
        ];
      };
      districts: {
        Row: {
          created_at: string;
          edlink_id: string | null;
          id: string;
          name: string | null;
          status: Database['public']['Enums']['row_status'];
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          edlink_id?: string | null;
          id?: string;
          name?: string | null;
          status?: Database['public']['Enums']['row_status'];
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          edlink_id?: string | null;
          id?: string;
          name?: string | null;
          status?: Database['public']['Enums']['row_status'];
          updated_at?: string;
        };
        Relationships: [];
      };
      edlink_confirmations: {
        Row: {
          confirmation_data: Json;
          created_at: string;
          id: string;
          token: string;
          used: boolean;
          user_id: string;
        };
        Insert: {
          confirmation_data: Json;
          created_at?: string;
          id?: string;
          token?: string;
          used?: boolean;
          user_id: string;
        };
        Update: {
          confirmation_data?: Json;
          created_at?: string;
          id?: string;
          token?: string;
          used?: boolean;
          user_id?: string;
        };
        Relationships: [];
      };
      files: {
        Row: {
          bucket: string;
          created_at: string;
          id: string;
          type: string;
          user_id: string;
        };
        Insert: {
          bucket: string;
          created_at?: string;
          id?: string;
          type: string;
          user_id: string;
        };
        Update: {
          bucket?: string;
          created_at?: string;
          id?: string;
          type?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'files_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      generation_cost_logs: {
        Row: {
          completed_at: string;
          completion_tokens: number;
          created_at: string;
          function_name: string | null;
          generation_id: string;
          model_slug: string;
          prompt_tokens: number;
          properties: Json;
          provider_name: string;
          requested_at: string;
          tool_config_id: string | null;
          tool_id: string | null;
          user_id: string | null;
        };
        Insert: {
          completed_at: string;
          completion_tokens?: number;
          created_at?: string;
          function_name?: string | null;
          generation_id: string;
          model_slug: string;
          prompt_tokens?: number;
          properties?: Json;
          provider_name: string;
          requested_at: string;
          tool_config_id?: string | null;
          tool_id?: string | null;
          user_id?: string | null;
        };
        Update: {
          completed_at?: string;
          completion_tokens?: number;
          created_at?: string;
          function_name?: string | null;
          generation_id?: string;
          model_slug?: string;
          prompt_tokens?: number;
          properties?: Json;
          provider_name?: string;
          requested_at?: string;
          tool_config_id?: string | null;
          tool_id?: string | null;
          user_id?: string | null;
        };
        Relationships: [];
      };
      generation_links: {
        Row: {
          access_type: Database['public']['Enums']['generation_link_access_type'];
          created_at: string | null;
          generation_id: number;
          id: string;
          org_id: string | null;
          user_id: string;
          visits: number;
        };
        Insert: {
          access_type?: Database['public']['Enums']['generation_link_access_type'];
          created_at?: string | null;
          generation_id: number;
          id?: string;
          org_id?: string | null;
          user_id: string;
          visits?: number;
        };
        Update: {
          access_type?: Database['public']['Enums']['generation_link_access_type'];
          created_at?: string | null;
          generation_id?: number;
          id?: string;
          org_id?: string | null;
          user_id?: string;
          visits?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'generation_links_generation_id_fkey';
            columns: ['generation_id'];
            isOneToOne: true;
            referencedRelation: 'generations';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'generation_links_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'generation_links_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'generation_links_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      generations: {
        Row: {
          created_at: string | null;
          deleted: boolean | null;
          email: string | null;
          id: number;
          inputs: Json | null;
          output: string | null;
          saved: boolean | null;
          template_type: string | null;
          thread_id: string | null;
          title: string | null;
          user_id: string | null;
          word_count: number | null;
          generation_title_output: string | null;
          generation_tool_title_output: string | null;
        };
        Insert: {
          created_at?: string | null;
          deleted?: boolean | null;
          email?: string | null;
          id?: number;
          inputs?: Json | null;
          output?: string | null;
          saved?: boolean | null;
          template_type?: string | null;
          thread_id?: string | null;
          title?: string | null;
          user_id?: string | null;
          word_count?: number | null;
        };
        Update: {
          created_at?: string | null;
          deleted?: boolean | null;
          email?: string | null;
          id?: number;
          inputs?: Json | null;
          output?: string | null;
          saved?: boolean | null;
          template_type?: string | null;
          thread_id?: string | null;
          title?: string | null;
          user_id?: string | null;
          word_count?: number | null;
        };
        Relationships: [
          {
            foreignKeyName: 'generations_thread_id_fkey';
            columns: ['thread_id'];
            isOneToOne: false;
            referencedRelation: 'threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'generations_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      llm_models: {
        Row: {
          created_at: string | null;
          id: string;
          label: string;
          provider: Database['public']['Enums']['llm_provider'];
          slug: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          label: string;
          provider?: Database['public']['Enums']['llm_provider'];
          slug: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          label?: string;
          provider?: Database['public']['Enums']['llm_provider'];
          slug?: string;
        };
        Relationships: [];
      };
      message_moderation_data: {
        Row: {
          assistant_thread_id: number;
          assistant_thread_message_id: number;
          created_at: string;
          data: Json;
          id: string;
          room_id: string;
          updated_at: string | null;
          user_id: string;
          viewed: boolean;
          flagged_words: string[];
        };
        Insert: {
          assistant_thread_id: number;
          assistant_thread_message_id: number;
          created_at?: string;
          data: Json;
          id?: string;
          room_id: string;
          updated_at?: string | null;
          user_id: string;
          viewed?: boolean;
          flagged_words?: string[];
        };
        Update: {
          assistant_thread_id?: number;
          assistant_thread_message_id?: number;
          created_at?: string;
          data?: Json;
          id?: string;
          room_id?: string;
          updated_at?: string | null;
          user_id?: string;
          viewed?: boolean;
          flagged_words?: string[];
        };
        Relationships: [
          {
            foreignKeyName: 'message_moderation_data_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'message_moderation_data_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'most_recent_room_student_messages';
            referencedColumns: ['assistant_thread_id'];
          },
          {
            foreignKeyName: 'message_moderation_data_assistant_thread_message_id_fkey';
            columns: ['assistant_thread_message_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_thread_messages';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'message_moderation_data_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'message_moderation_data_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'message_moderation_data_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      org_attributes: {
        Row: {
          active: boolean;
          created_at: string;
          id: number;
          name: string;
          org_id: string;
          updated_at: string;
          value: string;
        };
        Insert: {
          active?: boolean;
          created_at?: string;
          id?: number;
          name: string;
          org_id: string;
          updated_at?: string;
          value: string;
        };
        Update: {
          active?: boolean;
          created_at?: string;
          id?: number;
          name?: string;
          org_id?: string;
          updated_at?: string;
          value?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'emails_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'emails_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_districts: {
        Row: {
          created_at: string;
          district_id: string;
          org_id: string;
        };
        Insert: {
          created_at?: string;
          district_id: string;
          org_id: string;
        };
        Update: {
          created_at?: string;
          district_id?: string;
          org_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'org_district_id_fkey';
            columns: ['district_id'];
            isOneToOne: false;
            referencedRelation: 'districts';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'org_district_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'org_district_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_domains: {
        Row: {
          active: boolean;
          created_at: string;
          domain: string;
          id: number;
          org_id: string;
          set_user_org_id: boolean;
          updated_at: string;
        };
        Insert: {
          active?: boolean;
          created_at?: string;
          domain: string;
          id?: number;
          org_id: string;
          set_user_org_id?: boolean;
          updated_at?: string;
        };
        Update: {
          active?: boolean;
          created_at?: string;
          domain?: string;
          id?: number;
          org_id?: string;
          set_user_org_id?: boolean;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'domains_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'domains_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_emails: {
        Row: {
          active: boolean;
          created_at: string;
          email: string;
          id: number;
          org_id: string;
          updated_at: string;
        };
        Insert: {
          active?: boolean;
          created_at?: string;
          email: string;
          id?: number;
          org_id: string;
          updated_at?: string;
        };
        Update: {
          active?: boolean;
          created_at?: string;
          email?: string;
          id?: number;
          org_id?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'emails_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'emails_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_schools: {
        Row: {
          created_at: string;
          org_id: string;
          school_id: string;
        };
        Insert: {
          created_at?: string;
          org_id: string;
          school_id: string;
        };
        Update: {
          created_at?: string;
          org_id?: string;
          school_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'org_school_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'org_school_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'org_school_school_id_fkey';
            columns: ['school_id'];
            isOneToOne: false;
            referencedRelation: 'schools';
            referencedColumns: ['id'];
          },
        ];
      };
      org_sync_errors: {
        Row: {
          created_at: string;
          edlink_id: string;
          entity: Database['public']['Enums']['org_sync_event_entity'] | null;
          entity_id: string | null;
          error: string | null;
          id: string;
          message: string | null;
          org_sync_event_id: string | null;
          org_sync_event_type: Database['public']['Enums']['org_sync_event_type'] | null;
          org_sync_id: string;
          raw_data: Json | null;
          serial_id: number;
        };
        Insert: {
          created_at?: string;
          edlink_id: string;
          entity?: Database['public']['Enums']['org_sync_event_entity'] | null;
          entity_id?: string | null;
          error?: string | null;
          id?: string;
          message?: string | null;
          org_sync_event_id?: string | null;
          org_sync_event_type?: Database['public']['Enums']['org_sync_event_type'] | null;
          org_sync_id: string;
          raw_data?: Json | null;
          serial_id?: number;
        };
        Update: {
          created_at?: string;
          edlink_id?: string;
          entity?: Database['public']['Enums']['org_sync_event_entity'] | null;
          entity_id?: string | null;
          error?: string | null;
          id?: string;
          message?: string | null;
          org_sync_event_id?: string | null;
          org_sync_event_type?: Database['public']['Enums']['org_sync_event_type'] | null;
          org_sync_id?: string;
          raw_data?: Json | null;
          serial_id?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'org_sync_errors_org_sync_event_id_fkey';
            columns: ['org_sync_event_id'];
            isOneToOne: false;
            referencedRelation: 'org_sync_events';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'org_sync_errors_org_sync_id_fkey';
            columns: ['org_sync_id'];
            isOneToOne: false;
            referencedRelation: 'org_syncs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_sync_events: {
        Row: {
          created_at: string;
          edlink_id: string | null;
          entity: Database['public']['Enums']['org_sync_event_entity'] | null;
          id: string;
          increment: number;
          org_sync_id: string;
          sync_diffs: Json | null;
          type: Database['public']['Enums']['org_sync_event_type'] | null;
        };
        Insert: {
          created_at?: string;
          edlink_id?: string | null;
          entity?: Database['public']['Enums']['org_sync_event_entity'] | null;
          id?: string;
          increment?: number;
          org_sync_id: string;
          sync_diffs?: Json | null;
          type?: Database['public']['Enums']['org_sync_event_type'] | null;
        };
        Update: {
          created_at?: string;
          edlink_id?: string | null;
          entity?: Database['public']['Enums']['org_sync_event_entity'] | null;
          id?: string;
          increment?: number;
          org_sync_id?: string;
          sync_diffs?: Json | null;
          type?: Database['public']['Enums']['org_sync_event_type'] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'org_sync_event_org_sync_id_fkey';
            columns: ['org_sync_id'];
            isOneToOne: false;
            referencedRelation: 'org_syncs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_sync_previews: {
        Row: {
          created_at: string;
          edlink_integration_id: string;
          id: string;
          org_id: string;
          status: Database['public']['Enums']['org_sync_preview_status'];
          updated_at: string;
          user_id: string | null;
          values: Json;
        };
        Insert: {
          created_at?: string;
          edlink_integration_id: string;
          id?: string;
          org_id: string;
          status?: Database['public']['Enums']['org_sync_preview_status'];
          updated_at?: string;
          user_id?: string | null;
          values?: Json;
        };
        Update: {
          created_at?: string;
          edlink_integration_id?: string;
          id?: string;
          org_id?: string;
          status?: Database['public']['Enums']['org_sync_preview_status'];
          updated_at?: string;
          user_id?: string | null;
          values?: Json;
        };
        Relationships: [
          {
            foreignKeyName: 'org_sync_previews_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'org_sync_previews_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'org_sync_previews_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      org_syncs: {
        Row: {
          finish_dt: string | null;
          id: string;
          org_id: string;
          start_dt: string;
          status: Database['public']['Enums']['org_sync_status'];
          trigger_run_id: string | null;
        };
        Insert: {
          finish_dt?: string | null;
          id?: string;
          org_id: string;
          start_dt?: string;
          status?: Database['public']['Enums']['org_sync_status'];
          trigger_run_id?: string | null;
        };
        Update: {
          finish_dt?: string | null;
          id?: string;
          org_id?: string;
          start_dt?: string;
          status?: Database['public']['Enums']['org_sync_status'];
          trigger_run_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'org_sync_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'org_sync_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      org_tool_access_overrides: {
        Row: {
          created_at: string;
          enabled: boolean;
          id: string;
          org_id: string;
          tool_id: string;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          enabled: boolean;
          id?: string;
          org_id: string;
          tool_id: string;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          enabled?: boolean;
          id?: string;
          org_id?: string;
          tool_id?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_org_tool_access_overrides__org_id';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'fk_org_tool_access_overrides__org_id';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_org_tool_access_overrides__tool_id';
            columns: ['tool_id'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      orgs: {
        Row: {
          active: boolean;
          auth_providers: Database['public']['Enums']['auth_provider'][];
          created_at: string | null;
          edlink_access_token_id: string | null;
          edlink_integration_id: string | null;
          id: string;
          logo_url: string;
          name: string;
          type: Database['public']['Enums']['org_type'];
          updated_at: string | null;
        };
        Insert: {
          active?: boolean;
          auth_providers?: Database['public']['Enums']['auth_provider'][];
          created_at?: string | null;
          edlink_access_token_id?: string | null;
          edlink_integration_id?: string | null;
          id?: string;
          logo_url: string;
          name: string;
          type: Database['public']['Enums']['org_type'];
          updated_at?: string | null;
        };
        Update: {
          active?: boolean;
          auth_providers?: Database['public']['Enums']['auth_provider'][];
          created_at?: string | null;
          edlink_access_token_id?: string | null;
          edlink_integration_id?: string | null;
          id?: string;
          logo_url?: string;
          name?: string;
          type?: Database['public']['Enums']['org_type'];
          updated_at?: string | null;
        };
        Relationships: [];
      };
      prompts: {
        Row: {
          created_at: string | null;
          id: number;
          key: string | null;
          label: string;
          prompt: string;
          user_id: string | null;
          variables: Json | null;
        };
        Insert: {
          created_at?: string | null;
          id?: number;
          key?: string | null;
          label: string;
          prompt: string;
          user_id?: string | null;
          variables?: Json | null;
        };
        Update: {
          created_at?: string | null;
          id?: number;
          key?: string | null;
          label?: string;
          prompt?: string;
          user_id?: string | null;
          variables?: Json | null;
        };
        Relationships: [
          {
            foreignKeyName: 'prompts_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      resources: {
        Row: {
          created_at: string | null;
          data: Json;
          deleted: boolean;
          id: number;
          title: string | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          data: Json;
          deleted?: boolean;
          id?: number;
          title?: string | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          data?: Json;
          deleted?: boolean;
          id?: number;
          title?: string | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'resources_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      room_copy_relationships: {
        Row: {
          child_room_id: string;
          created_at: string;
          id: string;
          parent_room_id: string;
        };
        Insert: {
          child_room_id: string;
          created_at?: string;
          id?: string;
          parent_room_id: string;
        };
        Update: {
          child_room_id?: string;
          created_at?: string;
          id?: string;
          parent_room_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'room_copy_relationships_child_room_id_fkey';
            columns: ['child_room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_copy_relationships_child_room_id_fkey';
            columns: ['child_room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_copy_relationships_parent_room_id_fkey';
            columns: ['parent_room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_copy_relationships_parent_room_id_fkey';
            columns: ['parent_room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
        ];
      };
      room_student_threads: {
        Row: {
          assistant_thread_id: number;
          created_at: string;
          id: string;
          room_student_id: string;
          room_tool_id: string | null;
          status: Database['public']['Enums']['row_status'];
        };
        Insert: {
          assistant_thread_id: number;
          created_at?: string;
          id?: string;
          room_student_id: string;
          room_tool_id?: string | null;
          status?: Database['public']['Enums']['row_status'];
        };
        Update: {
          assistant_thread_id?: number;
          created_at?: string;
          id?: string;
          room_student_id?: string;
          room_tool_id?: string | null;
          status?: Database['public']['Enums']['row_status'];
        };
        Relationships: [
          {
            foreignKeyName: 'fk_room_student_threads_room_tool_id';
            columns: ['room_tool_id'];
            isOneToOne: false;
            referencedRelation: 'room_tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_student_threads_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_threads';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_student_threads_assistant_thread_id_fkey';
            columns: ['assistant_thread_id'];
            isOneToOne: false;
            referencedRelation: 'most_recent_room_student_messages';
            referencedColumns: ['assistant_thread_id'];
          },
          {
            foreignKeyName: 'room_student_threads_room_student_id_fkey';
            columns: ['room_student_id'];
            isOneToOne: false;
            referencedRelation: 'room_students';
            referencedColumns: ['id'];
          },
        ];
      };
      room_student_tool_customizations: {
        Row: {
          created_at: string;
          id: string;
          room_student_tool_id: string;
          tool_customization_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          room_student_tool_id: string;
          tool_customization_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          room_student_tool_id?: string;
          tool_customization_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'room_student_tool_customizations_room_student_tool_id_fkey';
            columns: ['room_student_tool_id'];
            isOneToOne: false;
            referencedRelation: 'room_student_tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_student_tool_customizations_tool_customization_id_fkey';
            columns: ['tool_customization_id'];
            isOneToOne: false;
            referencedRelation: 'tool_customizations';
            referencedColumns: ['id'];
          },
        ];
      };
      room_student_tools: {
        Row: {
          created_at: string;
          id: string;
          room_student_id: string;
          tool_id: string;
          tool_uuid: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          room_student_id: string;
          tool_id: string;
          tool_uuid: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          room_student_id?: string;
          tool_id?: string;
          tool_uuid?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_room_student_tools_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_student_tools_room_student_id_fkey';
            columns: ['room_student_id'];
            isOneToOne: false;
            referencedRelation: 'room_students';
            referencedColumns: ['id'];
          },
        ];
      };
      room_students: {
        Row: {
          created_at: string;
          id: string;
          room_id: string;
          state: Database['public']['Enums']['room_state'];
          status: Database['public']['Enums']['row_status'];
          student_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          room_id: string;
          state?: Database['public']['Enums']['room_state'];
          status?: Database['public']['Enums']['row_status'];
          student_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          room_id?: string;
          state?: Database['public']['Enums']['room_state'];
          status?: Database['public']['Enums']['row_status'];
          student_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'room_students_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_students_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_students_student_id_fkey';
            columns: ['student_id'];
            isOneToOne: false;
            referencedRelation: 'students';
            referencedColumns: ['id'];
          },
        ];
      };
      room_teachers: {
        Row: {
          created_at: string;
          id: string;
          role: Database['public']['Enums']['room_teacher_role'];
          room_id: string;
          status: Database['public']['Enums']['row_status'];
          teacher_id: string;
          updated_at: string | null;
        };
        Insert: {
          created_at?: string;
          id?: string;
          role?: Database['public']['Enums']['room_teacher_role'];
          room_id: string;
          status?: Database['public']['Enums']['row_status'];
          teacher_id: string;
          updated_at?: string | null;
        };
        Update: {
          created_at?: string;
          id?: string;
          role?: Database['public']['Enums']['room_teacher_role'];
          room_id?: string;
          status?: Database['public']['Enums']['row_status'];
          teacher_id?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'room_teachers_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_teachers_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_teachers_teacher_id_fkey';
            columns: ['teacher_id'];
            isOneToOne: false;
            referencedRelation: 'teachers';
            referencedColumns: ['id'];
          },
        ];
      };
      room_tool_customizations: {
        Row: {
          created_at: string;
          id: string;
          room_tool_id: string;
          tool_customization_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          room_tool_id: string;
          tool_customization_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          room_tool_id?: string;
          tool_customization_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'room_tool_customizations_room_tool_id_fkey';
            columns: ['room_tool_id'];
            isOneToOne: false;
            referencedRelation: 'room_tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_tool_customizations_tool_customization_id_fkey';
            columns: ['tool_customization_id'];
            isOneToOne: false;
            referencedRelation: 'tool_customizations';
            referencedColumns: ['id'];
          },
        ];
      };
      room_tools: {
        Row: {
          created_at: string;
          id: string;
          room_id: string;
          tool_id: string;
          tool_uuid: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          room_id: string;
          tool_id: string;
          tool_uuid: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          room_id?: string;
          tool_id?: string;
          tool_uuid?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_room_tools_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_tools_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_tools_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
        ];
      };
      rooms: {
        Row: {
          avatar_color: string;
          created_at: string;
          creator_org_id: string | null;
          edited_at: string;
          grade_level: string;
          id: string;
          join_code: string | null;
          max_students: number;
          name: string;
          require_login: boolean;
          sharing_access_type: Database['public']['Enums']['room_sharing_access'];
          sharing_code: string;
          state: Database['public']['Enums']['room_state'];
          state_changed_at: string;
          status: Database['public']['Enums']['row_status'];
          teacher_join_code: string;
          updated_at: string;
        };
        Insert: {
          avatar_color?: string;
          created_at?: string;
          creator_org_id?: string | null;
          edited_at?: string;
          grade_level?: string;
          id?: string;
          join_code?: string | null;
          max_students?: number;
          name: string;
          require_login?: boolean;
          sharing_access_type?: Database['public']['Enums']['room_sharing_access'];
          sharing_code?: string;
          state?: Database['public']['Enums']['room_state'];
          state_changed_at?: string;
          status?: Database['public']['Enums']['row_status'];
          teacher_join_code?: string;
          updated_at?: string;
        };
        Update: {
          avatar_color?: string;
          created_at?: string;
          creator_org_id?: string | null;
          edited_at?: string;
          grade_level?: string;
          id?: string;
          join_code?: string | null;
          max_students?: number;
          name?: string;
          require_login?: boolean;
          sharing_access_type?: Database['public']['Enums']['room_sharing_access'];
          sharing_code?: string;
          state?: Database['public']['Enums']['room_state'];
          state_changed_at?: string;
          status?: Database['public']['Enums']['row_status'];
          teacher_join_code?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'rooms_creator_org_id_fkey';
            columns: ['creator_org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'rooms_creator_org_id_fkey';
            columns: ['creator_org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
        ];
      };
      schools: {
        Row: {
          created_at: string;
          edlink_id: string | null;
          id: string;
          name: string | null;
          status: Database['public']['Enums']['row_status'];
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          edlink_id?: string | null;
          id?: string;
          name?: string | null;
          status?: Database['public']['Enums']['row_status'];
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          edlink_id?: string | null;
          id?: string;
          name?: string | null;
          status?: Database['public']['Enums']['row_status'];
          updated_at?: string;
        };
        Relationships: [];
      };
      site_config_history: {
        Row: {
          id: number;
          key: string;
          new_value: Json | null;
          old_value: Json | null;
          updated_at: string | null;
        };
        Insert: {
          id?: number;
          key: string;
          new_value?: Json | null;
          old_value?: Json | null;
          updated_at?: string | null;
        };
        Update: {
          id?: number;
          key?: string;
          new_value?: Json | null;
          old_value?: Json | null;
          updated_at?: string | null;
        };
        Relationships: [];
      };
      site_configs: {
        Row: {
          created_at: string | null;
          id: number;
          key: string;
          sensitive: boolean;
          value: Json | null;
        };
        Insert: {
          created_at?: string | null;
          id?: number;
          key: string;
          sensitive?: boolean;
          value?: Json | null;
        };
        Update: {
          created_at?: string | null;
          id?: number;
          key?: string;
          sensitive?: boolean;
          value?: Json | null;
        };
        Relationships: [];
      };
      stripe_customers: {
        Row: {
          created_at: string | null;
          deleted: boolean;
          id: number;
          stripe_customer_id: string;
          stripe_price_id: string | null;
          stripe_product_id: string | null;
          stripe_subscription_id: string | null;
          user_id: string;
          user_subscription_id: number | null;
        };
        Insert: {
          created_at?: string | null;
          deleted?: boolean;
          id?: number;
          stripe_customer_id: string;
          stripe_price_id?: string | null;
          stripe_product_id?: string | null;
          stripe_subscription_id?: string | null;
          user_id: string;
          user_subscription_id?: number | null;
        };
        Update: {
          created_at?: string | null;
          deleted?: boolean;
          id?: number;
          stripe_customer_id?: string;
          stripe_price_id?: string | null;
          stripe_product_id?: string | null;
          stripe_subscription_id?: string | null;
          user_id?: string;
          user_subscription_id?: number | null;
        };
        Relationships: [
          {
            foreignKeyName: 'stripe_customers_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'stripe_customers_user_subscription_id_fkey';
            columns: ['user_subscription_id'];
            isOneToOne: false;
            referencedRelation: 'user_subscriptions';
            referencedColumns: ['id'];
          },
        ];
      };
      stripe_errors: {
        Row: {
          created_at: string | null;
          error_name: string | null;
          id: number;
          message: string | null;
          stack: string | null;
          stripe_event: string | null;
          stripe_object: string | null;
          type: string | null;
        };
        Insert: {
          created_at?: string | null;
          error_name?: string | null;
          id?: number;
          message?: string | null;
          stack?: string | null;
          stripe_event?: string | null;
          stripe_object?: string | null;
          type?: string | null;
        };
        Update: {
          created_at?: string | null;
          error_name?: string | null;
          id?: number;
          message?: string | null;
          stack?: string | null;
          stripe_event?: string | null;
          stripe_object?: string | null;
          type?: string | null;
        };
        Relationships: [];
      };
      stripe_logs: {
        Row: {
          api_version: string | null;
          created_at: string | null;
          data: Json | null;
          id: number;
          livemode: string;
          stripe_event_id: string;
          stripe_event_type: string;
          stripe_object: string;
        };
        Insert: {
          api_version?: string | null;
          created_at?: string | null;
          data?: Json | null;
          id?: number;
          livemode: string;
          stripe_event_id: string;
          stripe_event_type: string;
          stripe_object: string;
        };
        Update: {
          api_version?: string | null;
          created_at?: string | null;
          data?: Json | null;
          id?: number;
          livemode?: string;
          stripe_event_id?: string;
          stripe_event_type?: string;
          stripe_object?: string;
        };
        Relationships: [];
      };
      students: {
        Row: {
          created_at: string;
          id: string;
          status: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          status?: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          status?: Database['public']['Enums']['row_status'];
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'students_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      subscription_plans: {
        Row: {
          created_at: string | null;
          description: string | null;
          id: number;
          name: string;
        };
        Insert: {
          created_at?: string | null;
          description?: string | null;
          id?: number;
          name: string;
        };
        Update: {
          created_at?: string | null;
          description?: string | null;
          id?: number;
          name?: string;
        };
        Relationships: [];
      };
      survey_configs: {
        Row: {
          active: boolean;
          config: Json;
          created_at: string;
          id: string;
          key: string;
          name: string;
          updated_at: string;
        };
        Insert: {
          active?: boolean;
          config: Json;
          created_at?: string;
          id?: string;
          key: string;
          name: string;
          updated_at?: string;
        };
        Update: {
          active?: boolean;
          config?: Json;
          created_at?: string;
          id?: string;
          key?: string;
          name?: string;
          updated_at?: string;
        };
        Relationships: [];
      };
      survey_responses: {
        Row: {
          created_at: string;
          id: string;
          response: Json;
          survey_id: string;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          response: Json;
          survey_id: string;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          response?: Json;
          survey_id?: string;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'survey_responses_survey_id_fkey';
            columns: ['survey_id'];
            isOneToOne: false;
            referencedRelation: 'survey_configs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'survey_responses_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      teachers: {
        Row: {
          created_at: string;
          id: string;
          status: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          status?: Database['public']['Enums']['row_status'];
          user_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          status?: Database['public']['Enums']['row_status'];
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'teachers_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      threads: {
        Row: {
          created_at: string | null;
          hidden: boolean | null;
          id: string;
          label: string | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          hidden?: boolean | null;
          id?: string;
          label?: string | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          hidden?: boolean | null;
          id?: string;
          label?: string | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'threads_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      tool_configs: {
        Row: {
          archived: boolean;
          created_at: string | null;
          fields: Json;
          frequency_penalty: number | null;
          id: string;
          max_length: number | null;
          model_id: string;
          name: string;
          presence_penalty: number | null;
          prompt: string;
          publish_date: string | null;
          system_message: string;
          temperature: number | null;
          tool_id: string;
          top_p: number | null;
        };
        Insert: {
          archived?: boolean;
          created_at?: string | null;
          fields?: Json;
          frequency_penalty?: number | null;
          id?: string;
          max_length?: number | null;
          model_id: string;
          name: string;
          presence_penalty?: number | null;
          prompt?: string;
          publish_date?: string | null;
          system_message?: string;
          temperature?: number | null;
          tool_id: string;
          top_p?: number | null;
        };
        Update: {
          archived?: boolean;
          created_at?: string | null;
          fields?: Json;
          frequency_penalty?: number | null;
          id?: string;
          max_length?: number | null;
          model_id?: string;
          name?: string;
          presence_penalty?: number | null;
          prompt?: string;
          publish_date?: string | null;
          system_message?: string;
          temperature?: number | null;
          tool_id?: string;
          top_p?: number | null;
        };
        Relationships: [
          {
            foreignKeyName: 'tool_configs_model_fkey';
            columns: ['model_id'];
            isOneToOne: false;
            referencedRelation: 'llm_models';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'tool_configs_tool_id_fkey';
            columns: ['tool_id'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      tool_customization_copy_relationships: {
        Row: {
          child_tool_customization_id: string;
          created_at: string;
          id: string;
          parent_tool_customization_id: string;
        };
        Insert: {
          child_tool_customization_id: string;
          created_at?: string;
          id?: string;
          parent_tool_customization_id: string;
        };
        Update: {
          child_tool_customization_id?: string;
          created_at?: string;
          id?: string;
          parent_tool_customization_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'tool_customization_copy_relationships_child_tool_customization_id_fkey';
            columns: ['child_tool_customization_id'];
            isOneToOne: false;
            referencedRelation: 'tool_customizations';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'tool_customization_copy_relationships_parent_tool_customization_id_fkey';
            columns: ['parent_tool_customization_id'];
            isOneToOne: false;
            referencedRelation: 'tool_customizations';
            referencedColumns: ['id'];
          },
        ];
      };
      tool_customizations: {
        Row: {
          created_at: string;
          id: string;
          json_config: Json;
          name: string;
          sharing_access_type: Database['public']['Enums']['tool_customization_sharing_access'];
          sharing_code: string;
          teacher_id: string;
          tool_application: Database['public']['Enums']['tool_application_type'];
          tool_slug: string;
          tool_uuid: string;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          json_config?: Json;
          name?: string;
          sharing_access_type?: Database['public']['Enums']['tool_customization_sharing_access'];
          sharing_code?: string;
          teacher_id: string;
          tool_application?: Database['public']['Enums']['tool_application_type'];
          tool_slug: string;
          tool_uuid: string;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          json_config?: Json;
          name?: string;
          sharing_access_type?: Database['public']['Enums']['tool_customization_sharing_access'];
          sharing_code?: string;
          teacher_id?: string;
          tool_application?: Database['public']['Enums']['tool_application_type'];
          tool_slug?: string;
          tool_uuid?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_tool_customizations_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'tool_customizations_teacher_id_fkey';
            columns: ['teacher_id'];
            isOneToOne: false;
            referencedRelation: 'teachers';
            referencedColumns: ['id'];
          },
        ];
      };
      tool_translations: {
        Row: {
          created_at: string | null;
          description: string;
          fields: Json;
          language: string;
          source_snapshot: Json;
          title: string;
          tool_id: string;
          updated_at: string | null;
        };
        Insert: {
          created_at?: string | null;
          description: string;
          fields?: Json;
          language: string;
          source_snapshot?: Json;
          title: string;
          tool_id: string;
          updated_at?: string | null;
        };
        Update: {
          created_at?: string | null;
          description?: string;
          fields?: Json;
          language?: string;
          source_snapshot?: Json;
          title?: string;
          tool_id?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'tool_translations_tool_id_fkey';
            columns: ['tool_id'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      tool_usage_weekly: {
        Row: {
          count: number;
          tool_id: string;
          tool_uuid: string;
        };
        Insert: {
          count: number;
          tool_id: string;
          tool_uuid: string;
        };
        Update: {
          count?: number;
          tool_id?: string;
          tool_uuid?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_tool_usage_weekly_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      tools: {
        Row: {
          created_at: string | null;
          description: string;
          icon: string;
          id: string;
          is_hidden: boolean;
          is_hot: boolean;
          is_new: boolean;
          priority: number;
          published_config_id: string | null;
          roles: ('student' | 'teacher')[];
          slug: string;
          tags: string[];
          title: string;
          updated_at: string | null;
        };
        Insert: {
          created_at?: string | null;
          description: string;
          icon: string;
          id?: string;
          is_hidden?: boolean;
          is_hot?: boolean;
          is_new?: boolean;
          priority?: number;
          published_config_id?: string | null;
          roles?: ('student' | 'teacher')[];
          slug: string;
          tags?: string[];
          title: string;
          updated_at?: string | null;
        };
        Update: {
          created_at?: string | null;
          description?: string;
          icon?: string;
          id?: string;
          is_hidden?: boolean;
          is_hot?: boolean;
          is_new?: boolean;
          priority?: number;
          published_config_id?: string | null;
          roles?: ('student' | 'teacher')[];
          slug?: string;
          tags?: string[];
          title?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'tools_default_published_config_fkey';
            columns: ['published_config_id'];
            isOneToOne: false;
            referencedRelation: 'tool_configs';
            referencedColumns: ['id'];
          },
        ];
      };
      tools_categories: {
        Row: {
          category_id: string;
          created_at: string | null;
          id: string;
          tool_id: string;
        };
        Insert: {
          category_id: string;
          created_at?: string | null;
          id?: string;
          tool_id: string;
        };
        Update: {
          category_id?: string;
          created_at?: string | null;
          id?: string;
          tool_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'tools_categories_category_id_fkey';
            columns: ['category_id'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'tools_categories_tool_id_fkey';
            columns: ['tool_id'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
      user_assistant_thread_message_feedback: {
        Row: {
          assistant_thread_message_id: number;
          created_at: string | null;
          feedback_data: Json | null;
          feedback_type: Database['public']['Enums']['user_feedback_type'];
          id: number;
          user_id: string | null;
        };
        Insert: {
          assistant_thread_message_id: number;
          created_at?: string | null;
          feedback_data?: Json | null;
          feedback_type: Database['public']['Enums']['user_feedback_type'];
          id?: number;
          user_id?: string | null;
        };
        Update: {
          assistant_thread_message_id?: number;
          created_at?: string | null;
          feedback_data?: Json | null;
          feedback_type?: Database['public']['Enums']['user_feedback_type'];
          id?: number;
          user_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'user_assistant_thread_message_feedback_assistant_thread_message';
            columns: ['assistant_thread_message_id'];
            isOneToOne: false;
            referencedRelation: 'assistant_thread_messages';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_assistant_thread_message_feedback_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_attributes: {
        Row: {
          active: boolean;
          created_at: string;
          id: number;
          name: string;
          updated_at: string;
          user_id: string;
          value: string;
        };
        Insert: {
          active?: boolean;
          created_at?: string;
          id?: number;
          name: string;
          updated_at?: string;
          user_id: string;
          value: string;
        };
        Update: {
          active?: boolean;
          created_at?: string;
          id?: number;
          name?: string;
          updated_at?: string;
          user_id?: string;
          value?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_attributes_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_districts: {
        Row: {
          activated_at: string | null;
          created_at: string;
          district_id: string;
          user_id: string;
          user_role: Database['public']['Enums']['user_role'] | null;
        };
        Insert: {
          activated_at?: string | null;
          created_at?: string;
          district_id: string;
          user_id: string;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Update: {
          activated_at?: string | null;
          created_at?: string;
          district_id?: string;
          user_id?: string;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'user_district_district_id_fkey';
            columns: ['district_id'];
            isOneToOne: true;
            referencedRelation: 'districts';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_district_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_favorites: {
        Row: {
          created_at: string | null;
          id: string;
          tool_id: string;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          tool_id: string;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          tool_id?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_favorites_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_favorites_ordered: {
        Row: {
          created_at: string | null;
          id: string;
          tools: string[] | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          tools?: string[] | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          tools?: string[] | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_favorites_ordered_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_image_generations: {
        Row: {
          created_at: string | null;
          id: string;
          inputs: Json;
          prompt: string | null;
          user_id: string | null;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          inputs: Json;
          prompt?: string | null;
          user_id?: string | null;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          inputs?: Json;
          prompt?: string | null;
          user_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'user_image_generations_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_nudges: {
        Row: {
          completed_nudges: string[];
          created_at: string;
          id: string;
          updated_at: string;
          user_id: string;
          user_serial_id: number;
        };
        Insert: {
          completed_nudges: string[];
          created_at?: string;
          id?: string;
          updated_at?: string;
          user_id: string;
          user_serial_id: number;
        };
        Update: {
          completed_nudges?: string[];
          created_at?: string;
          id?: string;
          updated_at?: string;
          user_id?: string;
          user_serial_id?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'user_nudges_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_org_history: {
        Row: {
          created_at: string | null;
          id: string;
          new_org: string | null;
          old_org: string | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          new_org?: string | null;
          old_org?: string | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          new_org?: string | null;
          old_org?: string | null;
          user_id?: string;
        };
        Relationships: [];
      };
      user_prompts: {
        Row: {
          id: number;
          prompt_id: number;
          user_id: string;
        };
        Insert: {
          id?: number;
          prompt_id: number;
          user_id: string;
        };
        Update: {
          id?: number;
          prompt_id?: number;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_prompts_prompt_id_fkey';
            columns: ['prompt_id'];
            isOneToOne: false;
            referencedRelation: 'prompts';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_prompts_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_role_history: {
        Row: {
          created_at: string | null;
          id: string;
          new_user_role: string;
          old_user_role: string;
          org_id: string | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          new_user_role: string;
          old_user_role: string;
          org_id?: string | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          new_user_role?: string;
          old_user_role?: string;
          org_id?: string | null;
          user_id?: string;
        };
        Relationships: [];
      };
      user_schools: {
        Row: {
          created_at: string;
          school_id: string;
          user_id: string;
          user_role: Database['public']['Enums']['user_role'] | null;
        };
        Insert: {
          created_at?: string;
          school_id: string;
          user_id: string;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Update: {
          created_at?: string;
          school_id?: string;
          user_id?: string;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'user_school_school_id_fkey';
            columns: ['school_id'];
            isOneToOne: false;
            referencedRelation: 'schools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_school_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_secrets: {
        Row: {
          created_at: string | null;
          google_refresh_token: string | null;
          id_token: Json | null;
          user_id: string;
        };
        Insert: {
          created_at?: string | null;
          google_refresh_token?: string | null;
          id_token?: Json | null;
          user_id: string;
        };
        Update: {
          created_at?: string | null;
          google_refresh_token?: string | null;
          id_token?: Json | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_secrets_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_subscriptions: {
        Row: {
          cancel_at_period_end: boolean | null;
          canceled_at: string | null;
          created_at: string | null;
          current_period_end: string | null;
          current_period_start: string | null;
          id: number;
          subscription_plan_id: number;
          subscription_status: Database['public']['Enums']['subscription_status'];
          trial_end: string | null;
          trial_start: string | null;
          user_id: string;
        };
        Insert: {
          cancel_at_period_end?: boolean | null;
          canceled_at?: string | null;
          created_at?: string | null;
          current_period_end?: string | null;
          current_period_start?: string | null;
          id?: number;
          subscription_plan_id: number;
          subscription_status: Database['public']['Enums']['subscription_status'];
          trial_end?: string | null;
          trial_start?: string | null;
          user_id: string;
        };
        Update: {
          cancel_at_period_end?: boolean | null;
          canceled_at?: string | null;
          created_at?: string | null;
          current_period_end?: string | null;
          current_period_start?: string | null;
          id?: number;
          subscription_plan_id?: number;
          subscription_status?: Database['public']['Enums']['subscription_status'];
          trial_end?: string | null;
          trial_start?: string | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_subscriptions_subscription_plan_id_fkey';
            columns: ['subscription_plan_id'];
            isOneToOne: false;
            referencedRelation: 'subscription_plans';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_subscriptions_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: true;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_tool_access_overrides: {
        Row: {
          created_at: string;
          enabled: boolean;
          id: string;
          tool_id: string;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          enabled: boolean;
          id?: string;
          tool_id: string;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          enabled?: boolean;
          id?: string;
          tool_id?: string;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_user_tool_access_overrides__tool_id';
            columns: ['tool_id'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_user_tool_access_overrides__user_id';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
        ];
      };
      user_usage_log: {
        Row: {
          creation_day: number | null;
          creation_hour: number | null;
          creation_month: number | null;
          creation_year: number | null;
          log_id: number;
          tokens: number | null;
          user_id: string | null;
        };
        Insert: {
          creation_day?: number | null;
          creation_hour?: number | null;
          creation_month?: number | null;
          creation_year?: number | null;
          log_id?: number;
          tokens?: number | null;
          user_id?: string | null;
        };
        Update: {
          creation_day?: number | null;
          creation_hour?: number | null;
          creation_month?: number | null;
          creation_year?: number | null;
          log_id?: number;
          tokens?: number | null;
          user_id?: string | null;
        };
        Relationships: [];
      };
      users: {
        Row: {
          best_practices_completed: boolean;
          city: string | null;
          country: string | null;
          created_at: string | null;
          custom_subject: string | null;
          deleted_reason: string | null;
          district: string | null;
          edlink_id: string | null;
          email: string | null;
          grades: string[] | null;
          id: string;
          last_sign_in_at: string | null;
          locale: string;
          name: string | null;
          org_id: string | null;
          plan: number;
          profile_image: string | null;
          profile_status: Database['public']['Enums']['profile_status'] | null;
          role: string | null;
          school: string | null;
          serial_id: number;
          state: string | null;
          status: Database['public']['Enums']['row_status'];
          student_friendly_name: string | null;
          subjects: string[] | null;
          updated_at: string | null;
          user_role: Database['public']['Enums']['user_role'] | null;
        };
        Insert: {
          best_practices_completed?: boolean;
          city?: string | null;
          country?: string | null;
          created_at?: string | null;
          custom_subject?: string | null;
          deleted_reason?: string | null;
          district?: string | null;
          edlink_id?: string | null;
          email?: string | null;
          grades?: string[] | null;
          id?: string;
          last_sign_in_at?: string | null;
          locale?: string;
          name?: string | null;
          org_id?: string | null;
          plan?: number;
          profile_image?: string | null;
          profile_status?: Database['public']['Enums']['profile_status'] | null;
          role?: string | null;
          school?: string | null;
          serial_id?: number;
          state?: string | null;
          status?: Database['public']['Enums']['row_status'];
          student_friendly_name?: string | null;
          subjects?: string[] | null;
          updated_at?: string | null;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Update: {
          best_practices_completed?: boolean;
          city?: string | null;
          country?: string | null;
          created_at?: string | null;
          custom_subject?: string | null;
          deleted_reason?: string | null;
          district?: string | null;
          edlink_id?: string | null;
          email?: string | null;
          grades?: string[] | null;
          id?: string;
          last_sign_in_at?: string | null;
          locale?: string;
          name?: string | null;
          org_id?: string | null;
          plan?: number;
          profile_image?: string | null;
          profile_status?: Database['public']['Enums']['profile_status'] | null;
          role?: string | null;
          school?: string | null;
          serial_id?: number;
          state?: string | null;
          status?: Database['public']['Enums']['row_status'];
          student_friendly_name?: string | null;
          subjects?: string[] | null;
          updated_at?: string | null;
          user_role?: Database['public']['Enums']['user_role'] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'users_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
          {
            foreignKeyName: 'users_org_id_fkey';
            columns: ['org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'users_plan_fkey';
            columns: ['plan'];
            isOneToOne: false;
            referencedRelation: 'subscription_plans';
            referencedColumns: ['id'];
          },
        ];
      };
      youtube_transcripts: {
        Row: {
          created_at: string | null;
          id: string;
          title: string | null;
          transcript_segments: Json;
          youtube_id: string;
        };
        Insert: {
          created_at?: string | null;
          id?: string;
          title?: string | null;
          transcript_segments: Json;
          youtube_id: string;
        };
        Update: {
          created_at?: string | null;
          id?: string;
          title?: string | null;
          transcript_segments?: Json;
          youtube_id?: string;
        };
        Relationships: [];
      };
    };
    Views: {
      category_flag_threshold_baseline_view: {
        Row: {
          category: string | null;
          flag_threshold: number | null;
        };
        Relationships: [];
      };
      most_recent_room_student_messages: {
        Row: {
          assistant_thread_id: number | null;
          message: string | null;
          message_created_at: string | null;
          room_id: string | null;
          room_student_id: string | null;
          room_tool_id: string | null;
          tool_id: string | null;
          tool_uuid: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'fk_assistant_threads_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_room_student_threads_room_tool_id';
            columns: ['room_tool_id'];
            isOneToOne: false;
            referencedRelation: 'room_tools';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_student_threads_room_student_id_fkey';
            columns: ['room_student_id'];
            isOneToOne: false;
            referencedRelation: 'room_students';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_students_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_students_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
        ];
      };
      org_dashboard_rooms: {
        Row: {
          created_at: string;
          creator_org_id: string | null;
          grade_level: string;
          id: string;
          join_code: string;
          name: string;
          org_id: string | null;
          org_name: string | null;
          role: Database['public']['Enums']['room_teacher_role'];
          room_id: string;
          room_teacher_status: Database['public']['Enums']['row_status'];
          state: Database['public']['Enums']['room_state'];
          state_changed_at: string;
          status: Database['public']['Enums']['row_status'];
          student_count: number;
          teacher_id: string;
          teacher_join_code: string;
          user_email: string;
          user_name: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'room_teachers_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_teachers_room_id_fkey';
            columns: ['room_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'room_teachers_teacher_id_fkey';
            columns: ['teacher_id'];
            isOneToOne: false;
            referencedRelation: 'teachers';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'rooms_creator_org_id_fkey';
            columns: ['creator_org_id'];
            isOneToOne: false;
            referencedRelation: 'orgs';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'rooms_creator_org_id_fkey';
            columns: ['creator_org_id'];
            isOneToOne: false;
            referencedRelation: 'org_dashboard_rooms';
            referencedColumns: ['org_id'];
          },
        ];
      };
      tool_usage_view: {
        Row: {
          created_at: string | null;
          source: string | null;
          tool_id: string | null;
          tool_uuid: string | null;
          user_id: string | null;
        };
        Insert: {
          created_at?: string | null;
          source?: never;
          tool_id?: never;
          tool_uuid?: string | null;
          user_id?: string | null;
        };
        Update: {
          created_at?: string | null;
          source?: never;
          tool_id?: never;
          tool_uuid?: string | null;
          user_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'assistant_threads_user_id_fkey';
            columns: ['user_id'];
            isOneToOne: false;
            referencedRelation: 'users';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'fk_assistant_threads_tool';
            columns: ['tool_uuid'];
            isOneToOne: false;
            referencedRelation: 'tools';
            referencedColumns: ['id'];
          },
        ];
      };
    };
    Functions: {
      add_resource_and_link:
        | {
            Args: {
              thread_id: number;
              user_id: string;
            };
            Returns: undefined;
          }
        | {
            Args: {
              thread_id: number;
              user_id: string;
              data: Json;
            };
            Returns: undefined;
          }
        | {
            Args: {
              thread_id: number;
              user_id: string;
              data: Json;
              title: string;
            };
            Returns: undefined;
          };
      assistant_threads_resources_complex: {
        Args: {
          res: unknown;
        };
        Returns: string;
      };
      assistant_threads_searchable_complex: {
        Args: {
          at: unknown;
        };
        Returns: string;
      };
      complete_edlink_confirmation: {
        Args: {
          tokenarg: string;
        };
        Returns: undefined;
      };
      custom_access_token_hook: {
        Args: {
          event: Json;
        };
        Returns: Json;
      };
      delete_secret: {
        Args: {
          secret_name: string;
        };
        Returns: undefined;
      };
      generation_title_output: {
        Args: {
          '': unknown;
        };
        Returns: string;
      };
      generation_tool_title_output: {
        Args: {
          '': unknown;
        };
        Returns: string;
      };
      get_org_id: {
        Args: {
          email: string;
        };
        Returns: string;
      };
      get_org_id_by_domain: {
        Args: {
          email: string;
        };
        Returns: string;
      };
      get_org_id_by_email: {
        Args: {
          in_email: string;
        };
        Returns: string;
      };
      get_user_tokens_count_combined: {
        Args: {
          uid: string;
          target_date: string;
        };
        Returns: {
          tokens_this_hour: number;
          tokens_this_day: number;
          tokens_this_month: number;
        }[];
      };
      insert_secret: {
        Args: {
          name: string;
          secret: string;
        };
        Returns: string;
      };
      pick_random_hex_code: {
        Args: Record<PropertyKey, never>;
        Returns: string;
      };
      process_batch_assistant_threads: {
        Args: {
          p_row_offset: number;
          p_batch_size: number;
        };
        Returns: number;
      };
      process_batch_assistant_threads_raina: {
        Args: {
          p_row_offset: number;
          p_batch_size: number;
        };
        Returns: number;
      };
      read_secret: {
        Args: {
          secret_name: string;
        };
        Returns: string;
      };
      test_connectivity: {
        Args: Record<PropertyKey, never>;
        Returns: boolean;
      };
      update_tool_categories: {
        Args: {
          _tool_id: string;
          _category_ids: string[];
        };
        Returns: undefined;
      };
      update_weekly_tool_usage: {
        Args: Record<PropertyKey, never>;
        Returns: undefined;
      };
    };
    Enums: {
      assistant_thread_link_access_type: 'private' | 'public' | 'org';
      auth_provider: 'google' | 'azure' | 'edlink' | 'email';
      generation_link_access_type: 'private' | 'public' | 'org';
      llm_provider: 'openai' | 'anthropic' | 'perplexity' | 'vertex' | 'bedrock';
      org_sync_event_entity: 'district' | 'school' | 'person';
      org_sync_event_type: 'addition' | 'update' | 'deletion';
      org_sync_preview_status: 'pending' | 'approved' | 'invalidated' | 'rejected';
      org_sync_status: 'pending' | 'syncing' | 'finished' | 'errored';
      org_type: 'School' | 'District' | 'Group' | 'Organization' | 'MagicSchool';
      profile_status: 'new' | 'returning' | 'completed';
      room_sharing_access: 'private' | 'public' | 'org';
      room_state: 'active' | 'paused' | 'locked' | 'archived';
      room_teacher_role: 'creator' | 'coteacher' | 'pending' | 'rejected' | 'administrator';
      row_status: 'active' | 'deleted' | 'pending';
      subscription_status: 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid' | 'paused';
      tool_application_type: 'individual' | 'org' | 'room';
      user_feedback_type: 'thumbs_up' | 'thumbs_down' | 'export' | 'regenerate' | 'share' | 'teacher_report';
      user_role: 'student' | 'teacher' | 'org_admin' | 'internal' | 'superuser';
      tool_customization_sharing_access: 'private' | 'public' | 'org';
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
  reco: {
    Tables: {
      [_ in never]: never;
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      get_user_recommendations: {
        Args: {
          p_user_id: string;
        };
        Returns: {
          tool_name: string;
          tool_slug: string;
          tool_uuid: string;
          grade_subject: string;
          rank: number;
          tool_count: number;
        }[];
      };
    };
    Enums: {
      [_ in never]: never;
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
};

type PublicSchema = Database[Extract<keyof Database, 'public'>];

export type Tables<
  PublicTableNameOrOptions extends keyof (PublicSchema['Tables'] & PublicSchema['Views']) | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof (Database[PublicTableNameOrOptions['schema']]['Tables'] & Database[PublicTableNameOrOptions['schema']]['Views'])
    : never = never,
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? (Database[PublicTableNameOrOptions['schema']]['Tables'] & Database[PublicTableNameOrOptions['schema']]['Views'])[TableName] extends {
      Row: infer R;
    }
    ? R
    : never
  : PublicTableNameOrOptions extends keyof (PublicSchema['Tables'] & PublicSchema['Views'])
    ? (PublicSchema['Tables'] & PublicSchema['Views'])[PublicTableNameOrOptions] extends {
        Row: infer R;
      }
      ? R
      : never
    : never;

export type TablesInsert<
  PublicTableNameOrOptions extends keyof PublicSchema['Tables'] | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions['schema']]['Tables']
    : never = never,
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Insert: infer I;
    }
    ? I
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema['Tables']
    ? PublicSchema['Tables'][PublicTableNameOrOptions] extends {
        Insert: infer I;
      }
      ? I
      : never
    : never;

export type TablesUpdate<
  PublicTableNameOrOptions extends keyof PublicSchema['Tables'] | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions['schema']]['Tables']
    : never = never,
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Update: infer U;
    }
    ? U
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema['Tables']
    ? PublicSchema['Tables'][PublicTableNameOrOptions] extends {
        Update: infer U;
      }
      ? U
      : never
    : never;

export type Enums<
  PublicEnumNameOrOptions extends keyof PublicSchema['Enums'] | { schema: keyof Database },
  EnumName extends PublicEnumNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicEnumNameOrOptions['schema']]['Enums']
    : never = never,
> = PublicEnumNameOrOptions extends { schema: keyof Database }
  ? Database[PublicEnumNameOrOptions['schema']]['Enums'][EnumName]
  : PublicEnumNameOrOptions extends keyof PublicSchema['Enums']
    ? PublicSchema['Enums'][PublicEnumNameOrOptions]
    : never;

// Schema: public
// Enums
export enum AssistantThreadLinkAccessType {
  private = 'private',
  public = 'public',
  org = 'org',
}

export enum AuthProvider {
  google = 'google',
  azure = 'azure',
  edlink = 'edlink',
  email = 'email',
}

export enum GenerationLinkAccessType {
  private = 'private',
  public = 'public',
  org = 'org',
}

export enum LlmProvider {
  openai = 'openai',
  anthropic = 'anthropic',
  perplexity = 'perplexity',
  vertex = 'vertex',
  bedrock = 'bedrock',
}

export enum OrgSyncEventEntity {
  district = 'district',
  school = 'school',
  person = 'person',
}

export enum OrgSyncEventType {
  addition = 'addition',
  update = 'update',
  deletion = 'deletion',
}

export enum OrgSyncPreviewStatus {
  pending = 'pending',
  approved = 'approved',
  invalidated = 'invalidated',
  rejected = 'rejected',
}

export enum OrgSyncStatus {
  pending = 'pending',
  syncing = 'syncing',
  finished = 'finished',
  errored = 'errored',
}

export enum OrgType {
  School = 'School',
  District = 'District',
  Group = 'Group',
  Organization = 'Organization',
  MagicSchool = 'MagicSchool',
}

export enum ProfileStatus {
  new = 'new',
  returning = 'returning',
  completed = 'completed',
}

export enum RoomSharingAccess {
  private = 'private',
  public = 'public',
  org = 'org',
}

export enum RoomState {
  active = 'active',
  paused = 'paused',
  locked = 'locked',
  archived = 'archived',
}

export enum RoomTeacherRole {
  creator = 'creator',
  coteacher = 'coteacher',
  pending = 'pending',
  rejected = 'rejected',
  administrator = 'administrator',
}

export enum RowStatus {
  active = 'active',
  deleted = 'deleted',
  pending = 'pending',
}

export enum SubscriptionStatus {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  paused = 'paused',
}

export enum ToolApplicationType {
  individual = 'individual',
  org = 'org',
  room = 'room',
}

export enum UserFeedbackType {
  thumbs_up = 'thumbs_up',
  thumbs_down = 'thumbs_down',
  export = 'export',
  regenerate = 'regenerate',
  share = 'share',
  teacher_report = 'teacher_report',
}

export enum UserRole {
  student = 'student',
  teacher = 'teacher',
  org_admin = 'org_admin',
  internal = 'internal',
  superuser = 'superuser',
}

// Tables
export type AssistantThreadExemplar = Database['public']['Tables']['assistant_thread_exemplars']['Row'];
export type InsertAssistantThreadExemplar = Database['public']['Tables']['assistant_thread_exemplars']['Insert'];
export type UpdateAssistantThreadExemplar = Database['public']['Tables']['assistant_thread_exemplars']['Update'];

export type AssistantThreadLink = Database['public']['Tables']['assistant_thread_links']['Row'];
export type InsertAssistantThreadLink = Database['public']['Tables']['assistant_thread_links']['Insert'];
export type UpdateAssistantThreadLink = Database['public']['Tables']['assistant_thread_links']['Update'];

export type AssistantThreadMessageEdit = Database['public']['Tables']['assistant_thread_message_edits']['Row'];
export type InsertAssistantThreadMessageEdit = Database['public']['Tables']['assistant_thread_message_edits']['Insert'];
export type UpdateAssistantThreadMessageEdit = Database['public']['Tables']['assistant_thread_message_edits']['Update'];

export type AssistantThreadMessage = Database['public']['Tables']['assistant_thread_messages']['Row'];
export type InsertAssistantThreadMessage = Database['public']['Tables']['assistant_thread_messages']['Insert'];
export type UpdateAssistantThreadMessage = Database['public']['Tables']['assistant_thread_messages']['Update'];

export type AssistantThreadResource = Database['public']['Tables']['assistant_thread_resources']['Row'];
export type InsertAssistantThreadResource = Database['public']['Tables']['assistant_thread_resources']['Insert'];
export type UpdateAssistantThreadResource = Database['public']['Tables']['assistant_thread_resources']['Update'];

export type AssistantThread = Database['public']['Tables']['assistant_threads']['Row'];
export type InsertAssistantThread = Database['public']['Tables']['assistant_threads']['Insert'];
export type UpdateAssistantThread = Database['public']['Tables']['assistant_threads']['Update'];

export type Category = Database['public']['Tables']['categories']['Row'];
export type InsertCategory = Database['public']['Tables']['categories']['Insert'];
export type UpdateCategory = Database['public']['Tables']['categories']['Update'];

export type CategoryTranslation = Database['public']['Tables']['category_translations']['Row'];
export type InsertCategoryTranslation = Database['public']['Tables']['category_translations']['Insert'];
export type UpdateCategoryTranslation = Database['public']['Tables']['category_translations']['Update'];

export type District = Database['public']['Tables']['districts']['Row'];
export type InsertDistrict = Database['public']['Tables']['districts']['Insert'];
export type UpdateDistrict = Database['public']['Tables']['districts']['Update'];

export type EdlinkConfirmation = Database['public']['Tables']['edlink_confirmations']['Row'];
export type InsertEdlinkConfirmation = Database['public']['Tables']['edlink_confirmations']['Insert'];
export type UpdateEdlinkConfirmation = Database['public']['Tables']['edlink_confirmations']['Update'];

export type File = Database['public']['Tables']['files']['Row'];
export type InsertFile = Database['public']['Tables']['files']['Insert'];
export type UpdateFile = Database['public']['Tables']['files']['Update'];

export type GenerationLink = Database['public']['Tables']['generation_links']['Row'];
export type InsertGenerationLink = Database['public']['Tables']['generation_links']['Insert'];
export type UpdateGenerationLink = Database['public']['Tables']['generation_links']['Update'];

export type Generation = Database['public']['Tables']['generations']['Row'];
export type InsertGeneration = Database['public']['Tables']['generations']['Insert'];
export type UpdateGeneration = Database['public']['Tables']['generations']['Update'];

export type LlmModel = Database['public']['Tables']['llm_models']['Row'];
export type InsertLlmModel = Database['public']['Tables']['llm_models']['Insert'];
export type UpdateLlmModel = Database['public']['Tables']['llm_models']['Update'];

export type MessageModerationData = Database['public']['Tables']['message_moderation_data']['Row'];
export type InsertMessageModerationData = Database['public']['Tables']['message_moderation_data']['Insert'];
export type UpdateMessageModerationData = Database['public']['Tables']['message_moderation_data']['Update'];

export type OrgAttribute = Database['public']['Tables']['org_attributes']['Row'];
export type InsertOrgAttribute = Database['public']['Tables']['org_attributes']['Insert'];
export type UpdateOrgAttribute = Database['public']['Tables']['org_attributes']['Update'];

export type OrgDistrict = Database['public']['Tables']['org_districts']['Row'];
export type InsertOrgDistrict = Database['public']['Tables']['org_districts']['Insert'];
export type UpdateOrgDistrict = Database['public']['Tables']['org_districts']['Update'];

export type OrgDomain = Database['public']['Tables']['org_domains']['Row'];
export type InsertOrgDomain = Database['public']['Tables']['org_domains']['Insert'];
export type UpdateOrgDomain = Database['public']['Tables']['org_domains']['Update'];

export type OrgEmail = Database['public']['Tables']['org_emails']['Row'];
export type InsertOrgEmail = Database['public']['Tables']['org_emails']['Insert'];
export type UpdateOrgEmail = Database['public']['Tables']['org_emails']['Update'];

export type OrgSchool = Database['public']['Tables']['org_schools']['Row'];
export type InsertOrgSchool = Database['public']['Tables']['org_schools']['Insert'];
export type UpdateOrgSchool = Database['public']['Tables']['org_schools']['Update'];

export type OrgSyncError = Database['public']['Tables']['org_sync_errors']['Row'];
export type InsertOrgSyncError = Database['public']['Tables']['org_sync_errors']['Insert'];
export type UpdateOrgSyncError = Database['public']['Tables']['org_sync_errors']['Update'];

export type OrgSyncEvent = Database['public']['Tables']['org_sync_events']['Row'];
export type InsertOrgSyncEvent = Database['public']['Tables']['org_sync_events']['Insert'];
export type UpdateOrgSyncEvent = Database['public']['Tables']['org_sync_events']['Update'];

export type OrgSyncPreview = Database['public']['Tables']['org_sync_previews']['Row'];
export type InsertOrgSyncPreview = Database['public']['Tables']['org_sync_previews']['Insert'];
export type UpdateOrgSyncPreview = Database['public']['Tables']['org_sync_previews']['Update'];

export type OrgSync = Database['public']['Tables']['org_syncs']['Row'];
export type InsertOrgSync = Database['public']['Tables']['org_syncs']['Insert'];
export type UpdateOrgSync = Database['public']['Tables']['org_syncs']['Update'];

export type OrgToolAccessOverride = Database['public']['Tables']['org_tool_access_overrides']['Row'];
export type InsertOrgToolAccessOverride = Database['public']['Tables']['org_tool_access_overrides']['Insert'];
export type UpdateOrgToolAccessOverride = Database['public']['Tables']['org_tool_access_overrides']['Update'];

export type Org = Database['public']['Tables']['orgs']['Row'];
export type InsertOrg = Database['public']['Tables']['orgs']['Insert'];
export type UpdateOrg = Database['public']['Tables']['orgs']['Update'];

export type Prompt = Database['public']['Tables']['prompts']['Row'];
export type InsertPrompt = Database['public']['Tables']['prompts']['Insert'];
export type UpdatePrompt = Database['public']['Tables']['prompts']['Update'];

export type Resource = Database['public']['Tables']['resources']['Row'];
export type InsertResource = Database['public']['Tables']['resources']['Insert'];
export type UpdateResource = Database['public']['Tables']['resources']['Update'];

export type RoomCopyRelationship = Database['public']['Tables']['room_copy_relationships']['Row'];
export type InsertRoomCopyRelationship = Database['public']['Tables']['room_copy_relationships']['Insert'];
export type UpdateRoomCopyRelationship = Database['public']['Tables']['room_copy_relationships']['Update'];

export type RoomStudentThread = Database['public']['Tables']['room_student_threads']['Row'];
export type InsertRoomStudentThread = Database['public']['Tables']['room_student_threads']['Insert'];
export type UpdateRoomStudentThread = Database['public']['Tables']['room_student_threads']['Update'];

export type RoomStudentToolCustomization = Database['public']['Tables']['room_student_tool_customizations']['Row'];
export type InsertRoomStudentToolCustomization = Database['public']['Tables']['room_student_tool_customizations']['Insert'];
export type UpdateRoomStudentToolCustomization = Database['public']['Tables']['room_student_tool_customizations']['Update'];

export type RoomStudentTool = Database['public']['Tables']['room_student_tools']['Row'];
export type InsertRoomStudentTool = Database['public']['Tables']['room_student_tools']['Insert'];
export type UpdateRoomStudentTool = Database['public']['Tables']['room_student_tools']['Update'];

export type RoomStudent = Database['public']['Tables']['room_students']['Row'];
export type InsertRoomStudent = Database['public']['Tables']['room_students']['Insert'];
export type UpdateRoomStudent = Database['public']['Tables']['room_students']['Update'];

export type RoomTeacher = Database['public']['Tables']['room_teachers']['Row'];
export type InsertRoomTeacher = Database['public']['Tables']['room_teachers']['Insert'];
export type UpdateRoomTeacher = Database['public']['Tables']['room_teachers']['Update'];

export type RoomToolCustomization = Database['public']['Tables']['room_tool_customizations']['Row'];
export type InsertRoomToolCustomization = Database['public']['Tables']['room_tool_customizations']['Insert'];
export type UpdateRoomToolCustomization = Database['public']['Tables']['room_tool_customizations']['Update'];

export type RoomTool = Database['public']['Tables']['room_tools']['Row'];
export type InsertRoomTool = Database['public']['Tables']['room_tools']['Insert'];
export type UpdateRoomTool = Database['public']['Tables']['room_tools']['Update'];

export type Room = Database['public']['Tables']['rooms']['Row'];
export type InsertRoom = Database['public']['Tables']['rooms']['Insert'];
export type UpdateRoom = Database['public']['Tables']['rooms']['Update'];

export type School = Database['public']['Tables']['schools']['Row'];
export type InsertSchool = Database['public']['Tables']['schools']['Insert'];
export type UpdateSchool = Database['public']['Tables']['schools']['Update'];

export type SiteConfigHistory = Database['public']['Tables']['site_config_history']['Row'];
export type InsertSiteConfigHistory = Database['public']['Tables']['site_config_history']['Insert'];
export type UpdateSiteConfigHistory = Database['public']['Tables']['site_config_history']['Update'];

export type SiteConfig = Database['public']['Tables']['site_configs']['Row'];
export type InsertSiteConfig = Database['public']['Tables']['site_configs']['Insert'];
export type UpdateSiteConfig = Database['public']['Tables']['site_configs']['Update'];

export type StripeCustomer = Database['public']['Tables']['stripe_customers']['Row'];
export type InsertStripeCustomer = Database['public']['Tables']['stripe_customers']['Insert'];
export type UpdateStripeCustomer = Database['public']['Tables']['stripe_customers']['Update'];

export type StripeError = Database['public']['Tables']['stripe_errors']['Row'];
export type InsertStripeError = Database['public']['Tables']['stripe_errors']['Insert'];
export type UpdateStripeError = Database['public']['Tables']['stripe_errors']['Update'];

export type StripeLog = Database['public']['Tables']['stripe_logs']['Row'];
export type InsertStripeLog = Database['public']['Tables']['stripe_logs']['Insert'];
export type UpdateStripeLog = Database['public']['Tables']['stripe_logs']['Update'];

export type Student = Database['public']['Tables']['students']['Row'];
export type InsertStudent = Database['public']['Tables']['students']['Insert'];
export type UpdateStudent = Database['public']['Tables']['students']['Update'];

export type SubscriptionPlan = Database['public']['Tables']['subscription_plans']['Row'];
export type InsertSubscriptionPlan = Database['public']['Tables']['subscription_plans']['Insert'];
export type UpdateSubscriptionPlan = Database['public']['Tables']['subscription_plans']['Update'];

export type SurveyConfig = Database['public']['Tables']['survey_configs']['Row'];
export type InsertSurveyConfig = Database['public']['Tables']['survey_configs']['Insert'];
export type UpdateSurveyConfig = Database['public']['Tables']['survey_configs']['Update'];

export type SurveyResponse = Database['public']['Tables']['survey_responses']['Row'];
export type InsertSurveyResponse = Database['public']['Tables']['survey_responses']['Insert'];
export type UpdateSurveyResponse = Database['public']['Tables']['survey_responses']['Update'];

export type Teacher = Database['public']['Tables']['teachers']['Row'];
export type InsertTeacher = Database['public']['Tables']['teachers']['Insert'];
export type UpdateTeacher = Database['public']['Tables']['teachers']['Update'];

export type Thread = Database['public']['Tables']['threads']['Row'];
export type InsertThread = Database['public']['Tables']['threads']['Insert'];
export type UpdateThread = Database['public']['Tables']['threads']['Update'];

export type ToolConfig = Database['public']['Tables']['tool_configs']['Row'];
export type InsertToolConfig = Database['public']['Tables']['tool_configs']['Insert'];
export type UpdateToolConfig = Database['public']['Tables']['tool_configs']['Update'];

export type ToolCustomization = Database['public']['Tables']['tool_customizations']['Row'];
export type InsertToolCustomization = Database['public']['Tables']['tool_customizations']['Insert'];
export type UpdateToolCustomization = Database['public']['Tables']['tool_customizations']['Update'];

export type ToolTranslation = Database['public']['Tables']['tool_translations']['Row'];
export type InsertToolTranslation = Database['public']['Tables']['tool_translations']['Insert'];
export type UpdateToolTranslation = Database['public']['Tables']['tool_translations']['Update'];

export type ToolUsageWeekly = Database['public']['Tables']['tool_usage_weekly']['Row'];
export type InsertToolUsageWeekly = Database['public']['Tables']['tool_usage_weekly']['Insert'];
export type UpdateToolUsageWeekly = Database['public']['Tables']['tool_usage_weekly']['Update'];

export type Tool = Database['public']['Tables']['tools']['Row'];
export type InsertTool = Database['public']['Tables']['tools']['Insert'];
export type UpdateTool = Database['public']['Tables']['tools']['Update'];

export type ToolCategory = Database['public']['Tables']['tools_categories']['Row'];
export type InsertToolCategory = Database['public']['Tables']['tools_categories']['Insert'];
export type UpdateToolCategory = Database['public']['Tables']['tools_categories']['Update'];

export type UserAssistantThreadMessageFeedback = Database['public']['Tables']['user_assistant_thread_message_feedback']['Row'];
export type InsertUserAssistantThreadMessageFeedback = Database['public']['Tables']['user_assistant_thread_message_feedback']['Insert'];
export type UpdateUserAssistantThreadMessageFeedback = Database['public']['Tables']['user_assistant_thread_message_feedback']['Update'];

export type UserAttribute = Database['public']['Tables']['user_attributes']['Row'];
export type InsertUserAttribute = Database['public']['Tables']['user_attributes']['Insert'];
export type UpdateUserAttribute = Database['public']['Tables']['user_attributes']['Update'];

export type UserDistrict = Database['public']['Tables']['user_districts']['Row'];
export type InsertUserDistrict = Database['public']['Tables']['user_districts']['Insert'];
export type UpdateUserDistrict = Database['public']['Tables']['user_districts']['Update'];

export type UserFavorite = Database['public']['Tables']['user_favorites']['Row'];
export type InsertUserFavorite = Database['public']['Tables']['user_favorites']['Insert'];
export type UpdateUserFavorite = Database['public']['Tables']['user_favorites']['Update'];

export type UserFavoriteOrdered = Database['public']['Tables']['user_favorites_ordered']['Row'];
export type InsertUserFavoriteOrdered = Database['public']['Tables']['user_favorites_ordered']['Insert'];
export type UpdateUserFavoriteOrdered = Database['public']['Tables']['user_favorites_ordered']['Update'];

export type UserImageGeneration = Database['public']['Tables']['user_image_generations']['Row'];
export type InsertUserImageGeneration = Database['public']['Tables']['user_image_generations']['Insert'];
export type UpdateUserImageGeneration = Database['public']['Tables']['user_image_generations']['Update'];

export type UserNudge = Database['public']['Tables']['user_nudges']['Row'];
export type InsertUserNudge = Database['public']['Tables']['user_nudges']['Insert'];
export type UpdateUserNudge = Database['public']['Tables']['user_nudges']['Update'];

export type UserOrgHistory = Database['public']['Tables']['user_org_history']['Row'];
export type InsertUserOrgHistory = Database['public']['Tables']['user_org_history']['Insert'];
export type UpdateUserOrgHistory = Database['public']['Tables']['user_org_history']['Update'];

export type UserPrompt = Database['public']['Tables']['user_prompts']['Row'];
export type InsertUserPrompt = Database['public']['Tables']['user_prompts']['Insert'];
export type UpdateUserPrompt = Database['public']['Tables']['user_prompts']['Update'];

export type UserRoleHistory = Database['public']['Tables']['user_role_history']['Row'];
export type InsertUserRoleHistory = Database['public']['Tables']['user_role_history']['Insert'];
export type UpdateUserRoleHistory = Database['public']['Tables']['user_role_history']['Update'];

export type UserSchool = Database['public']['Tables']['user_schools']['Row'];
export type InsertUserSchool = Database['public']['Tables']['user_schools']['Insert'];
export type UpdateUserSchool = Database['public']['Tables']['user_schools']['Update'];

export type UserSecret = Database['public']['Tables']['user_secrets']['Row'];
export type InsertUserSecret = Database['public']['Tables']['user_secrets']['Insert'];
export type UpdateUserSecret = Database['public']['Tables']['user_secrets']['Update'];

export type UserSubscription = Database['public']['Tables']['user_subscriptions']['Row'];
export type InsertUserSubscription = Database['public']['Tables']['user_subscriptions']['Insert'];
export type UpdateUserSubscription = Database['public']['Tables']['user_subscriptions']['Update'];

export type UserToolAccessOverride = Database['public']['Tables']['user_tool_access_overrides']['Row'];
export type InsertUserToolAccessOverride = Database['public']['Tables']['user_tool_access_overrides']['Insert'];
export type UpdateUserToolAccessOverride = Database['public']['Tables']['user_tool_access_overrides']['Update'];

export type UserUsageLog = Database['public']['Tables']['user_usage_log']['Row'];
export type InsertUserUsageLog = Database['public']['Tables']['user_usage_log']['Insert'];
export type UpdateUserUsageLog = Database['public']['Tables']['user_usage_log']['Update'];

export type User = Database['public']['Tables']['users']['Row'];
export type InsertUser = Database['public']['Tables']['users']['Insert'];
export type UpdateUser = Database['public']['Tables']['users']['Update'];

export type YoutubeTranscript = Database['public']['Tables']['youtube_transcripts']['Row'];
export type InsertYoutubeTranscript = Database['public']['Tables']['youtube_transcripts']['Insert'];
export type UpdateYoutubeTranscript = Database['public']['Tables']['youtube_transcripts']['Update'];

export type UserNudges = Database['public']['Tables']['user_nudges']['Row'];
export type InsertUserNudges = Database['public']['Tables']['user_nudges']['Insert'];
export type UpdateUserNudges = Database['public']['Tables']['user_nudges']['Update'];

export type GenerationCostLog = Database['public']['Tables']['generation_cost_logs']['Row'];
export type InsertGenerationCostLog = Database['public']['Tables']['generation_cost_logs']['Insert'];

// Views
export type CategoryFlagThresholdBaselineView = Database['public']['Views']['category_flag_threshold_baseline_view']['Row'];

export type MostRecentRoomStudentMessage = Database['public']['Views']['most_recent_room_student_messages']['Row'];

export type OrgDashboardRoom = Database['public']['Views']['org_dashboard_rooms']['Row'];

export type ToolUsageView = Database['public']['Views']['tool_usage_view']['Row'];

// Functions
export type ArgsAddResourceAndLink = Database['public']['Functions']['add_resource_and_link']['Args'];
export type ReturnTypeAddResourceAndLink = Database['public']['Functions']['add_resource_and_link']['Returns'];

export type ArgsAssistantThreadResourceComplex = Database['public']['Functions']['assistant_threads_resources_complex']['Args'];
export type ReturnTypeAssistantThreadResourceComplex = Database['public']['Functions']['assistant_threads_resources_complex']['Returns'];

export type ArgsAssistantThreadSearchableComplex = Database['public']['Functions']['assistant_threads_searchable_complex']['Args'];
export type ReturnTypeAssistantThreadSearchableComplex = Database['public']['Functions']['assistant_threads_searchable_complex']['Returns'];

export type ArgsCompleteEdlinkConfirmation = Database['public']['Functions']['complete_edlink_confirmation']['Args'];
export type ReturnTypeCompleteEdlinkConfirmation = Database['public']['Functions']['complete_edlink_confirmation']['Returns'];

export type ArgsCustomAccessTokenHook = Database['public']['Functions']['custom_access_token_hook']['Args'];
export type ReturnTypeCustomAccessTokenHook = Database['public']['Functions']['custom_access_token_hook']['Returns'];

export type ArgsDeleteSecret = Database['public']['Functions']['delete_secret']['Args'];
export type ReturnTypeDeleteSecret = Database['public']['Functions']['delete_secret']['Returns'];

export type ArgsGenerationTitleOutput = Database['public']['Functions']['generation_title_output']['Args'];
export type ReturnTypeGenerationTitleOutput = Database['public']['Functions']['generation_title_output']['Returns'];

export type ArgsGenerationToolTitleOutput = Database['public']['Functions']['generation_tool_title_output']['Args'];
export type ReturnTypeGenerationToolTitleOutput = Database['public']['Functions']['generation_tool_title_output']['Returns'];

export type ArgsGetOrgId = Database['public']['Functions']['get_org_id']['Args'];
export type ReturnTypeGetOrgId = Database['public']['Functions']['get_org_id']['Returns'];

export type ArgsGetOrgIdByDomain = Database['public']['Functions']['get_org_id_by_domain']['Args'];
export type ReturnTypeGetOrgIdByDomain = Database['public']['Functions']['get_org_id_by_domain']['Returns'];

export type ArgsGetOrgIdByEmail = Database['public']['Functions']['get_org_id_by_email']['Args'];
export type ReturnTypeGetOrgIdByEmail = Database['public']['Functions']['get_org_id_by_email']['Returns'];

export type ArgsGetUserTokenCountCombined = Database['public']['Functions']['get_user_tokens_count_combined']['Args'];
export type ReturnTypeGetUserTokenCountCombined = Database['public']['Functions']['get_user_tokens_count_combined']['Returns'];

export type ArgsPickRandomHexCode = Database['public']['Functions']['pick_random_hex_code']['Args'];
export type ReturnTypePickRandomHexCode = Database['public']['Functions']['pick_random_hex_code']['Returns'];

export type ArgsInsertSecret = Database['public']['Functions']['insert_secret']['Args'];
export type ReturnTypeInsertSecret = Database['public']['Functions']['insert_secret']['Returns'];

export type ArgsProcessBatchAssistantThread = Database['public']['Functions']['process_batch_assistant_threads']['Args'];
export type ReturnTypeProcessBatchAssistantThread = Database['public']['Functions']['process_batch_assistant_threads']['Returns'];

export type ArgsProcessBatchAssistantThreadRaina = Database['public']['Functions']['process_batch_assistant_threads_raina']['Args'];
export type ReturnTypeProcessBatchAssistantThreadRaina =
  Database['public']['Functions']['process_batch_assistant_threads_raina']['Returns'];

export type ArgsReadSecret = Database['public']['Functions']['read_secret']['Args'];
export type ReturnTypeReadSecret = Database['public']['Functions']['read_secret']['Returns'];

export type ArgsTestConnectivity = Database['public']['Functions']['test_connectivity']['Args'];
export type ReturnTypeTestConnectivity = Database['public']['Functions']['test_connectivity']['Returns'];

export type ArgsUpdateToolCategory = Database['public']['Functions']['update_tool_categories']['Args'];
export type ReturnTypeUpdateToolCategory = Database['public']['Functions']['update_tool_categories']['Returns'];

export type ArgsUpdateWeeklyToolUsage = Database['public']['Functions']['update_weekly_tool_usage']['Args'];
export type ReturnTypeUpdateWeeklyToolUsage = Database['public']['Functions']['update_weekly_tool_usage']['Returns'];

// Schema: reco
// Functions
export type ArgsGetUserRecommendation = Database['reco']['Functions']['get_user_recommendations']['Args'];
export type ReturnTypeGetUserRecommendations = Database['reco']['Functions']['get_user_recommendations']['Returns'];
