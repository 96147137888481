export default [
  'assault',
  'asshole',
  'beat',
  'bitch',
  'blow',
  'bomb',
  'booze',
  'bullshit',
  'crap',
  'drug',
  'drunk',
  'firearm',
  'fuck',
  'gun',
  'hate',
  'high',
  'idiot',
  'jerk',
  'kill',
  'knife',
  'rifle',
  'shit',
  'shoot',
  'steal',
  'stupid',
  'suicide',
];
